<template>
  <div class="z-login-page">
    <div class="login-page-bg" :style="{backgroundImage: 'url(' + picArray.bgPic + ')' }">
    </div>
    <div class="login-page-container">
      <div class="login-logo-conatiner">
        <img :src="picArray.tgLogo">
      </div>
      <div class="login-form-container">
        <div class="login-form-wrap">
          <div class="login-form-box" v-loading="loading">
            <div class="login-form-box-header">
<!--              <img :src="picArray.userLogin">-->
              <h2>供应商注册</h2>
            </div>
            <div class="login-form-box-body">
              <el-form label-position="right"
                       :model="formData"
                       :rules="rules"
                       ref="supplierForm"
                       label-width="90px"
                       element-loading-background="rgba(0, 0, 0, 0.8)">
                <el-form-item label="供应商名称" prop="name">
                  <el-input v-model="formData.name" placeholder="请输入供应商名称"></el-input>
                </el-form-item>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="供应商分类" prop="type">
                      <el-select v-model="formData.type" multiple style="width: 100%">
                        <el-option v-for="item in SUPPLIER_TYPE" :value="item.key" :label="item.label" :key="item.key"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="封面" prop="cover">
                      <file-upload-btn @uploadSuccess="handleUploadSuccess"></file-upload-btn>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="供应商地址" prop="location">
                      <el-input v-model="formData.location" placeholder="请输入供应商地址"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="标签" prop="tag">
                      <el-input v-model="formData.tag" placeholder="多个标签用逗号隔开"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="供应商风格" prop="style">
                      <el-input v-model="formData.style" placeholder="请输入风格"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="供应商描述" prop="description">
                      <el-input v-model="formData.description" placeholder="请输入与供应商描述"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-form-item label="登录密码" prop="password">
                    <el-input v-model="formData.password" placeholder="请输入密码"></el-input>
                  </el-form-item>
                </el-row>
              </el-form>
              <el-button type="primary" @click="trySubmit" :loading="loading">确认注册</el-button>
            </div>
            <div class="login-form-header"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bgPic from '@/assets/loginPage/bg.png'
import tgLogo from '@/assets/loginPage/tgLogo1.png'
import userLogin from '@/assets/loginPage/userLogin.png'
import lockedIcon from '@/assets/loginPage/Locked.png'
import userIcon from '@/assets/loginPage/user_logo.png'
import { validateLogin } from '@/config/validateRules'
import FileUploadBtn from 'modules/components/fileUploadBtn/index.vue'
import { SUPPLIER_TYPE } from '@/views/supplierManage/option'

function resetSupplier() {
  return {
    name: '',
    location: '',
    cover: '',
    type: [],
    tag: '',
    style: '',
    description: '',
    password: ''
  }
}
export const rules = {
  name: [
    { required: true, message: '请输入供应商名称', trigger: 'change' }
  ],
  type: [
    { type: 'array', required: true, message: '请选择供应商类型', trigger: 'change' }
  ],
  cover: [
    { required: true, message: '请选择封面', trigger: 'change' }
  ],
  tag: [
    { required: true, message: '请供应商标签', trigger: 'change' }
  ],
  location: [
    { required: true, message: '请供应商标签', trigger: 'change' }
  ],
  style: [
    { required: true, message: '请输入供应商风格', trigger: 'change' }
  ],
  description: [
    { required: true, message: '请输入供应商简介', trigger: 'change' }
  ],
  password: [
    { required: true, message: '请输入登录密码', trigger: 'change' }
  ]
}
export default {
  name: 'register',
  components: { FileUploadBtn },
  data() {
    return {
      SUPPLIER_TYPE,
      picArray: {
        bgPic: bgPic,
        tgLogo: tgLogo,
        userLogin: userLogin,
        lockedIcon: lockedIcon,
        userIcon: userIcon
      },
      loading: false,
      reSubmit: false, // 重复提交
      validateRules: validateLogin,
      formData: resetSupplier(),
      rules
    }
  },
  methods: {
    handleUploadSuccess(url) {
      this.formData.cover = url
    },
    trySubmit() {
      this.reSubmit = true
      this.$refs.supplierForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.$store.dispatch('register', this.formData).then(() => {
            this.$router.push({
              path: '/login'
            })
          }, () => {
            this.$message('注册失败请于管理员联系')
          }).finally(() => {
            this.loading = false
            this.reSubmit = false
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@media (min-height: 620px) {
  .z-login-page .login-page-container {
    top: 20% !important;
  }
}
.z-login-page {
  .login-page-container {
    .login-form-container {
      .login-form-wrap {
        margin: 0 auto;
        width: 100%;
        position: relative;
        height: 405px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 10px;
        .login-form-box {
          background-color: #ffffff;
          border-radius: 10px;
          width: 665px;
          height: 385px;
          top: 10px;
          left: 10px;
          position: absolute;
          .login-form-box-body {
            .login-form {
              .login-form-label {
                left: 0;
                top: 1px;
                position: absolute;
                width: 58px;
                height: 52px;
                line-height: 52px;
                text-align: center;
              }
              input {
                &:focus {
                  border-color: rgb(104, 182, 255);
                  border-style: solid;
                  background-color: rgb(255, 255, 255);
                  box-shadow: 0px 0px 4px 0px rgba(1, 126, 244, 0.45);
                }
                height: 52px;
                box-sizing: border-box;
                width: 100%;
                font-size: 15px;
                padding: 15px 15px 15px 58px;
                border: 1px solid #ddd;
                border-radius: 0;
              }
              .login-form-button,
              .login-form-input {
                position: relative;
                margin-top: 20px;
              }
              .login-form-button {
                a {
                  color: #fff;
                }
                &:hover {
                  background-color: rgba(5, 117, 255, 0.8);
                }
                cursor: pointer;
                height: 52px;
                line-height: 52px;
                background-color: #0575ff;
                font-size: 20px;
              }
            }
            .logon-error {
              div {
                padding-right: 6px;
                float: left;
                width: 20px;
                height: 20px;
              }
              p {
                white-space: normal;
                word-wrap: break-word;
                margin: 0;
                float: left;
                width: 313px;
              }
              font-size: 16px;
              color: #ff4242;
              text-align: left;
              margin-top: 5px;
            }
            & :after {
              display: block;
              clear: both;
              content: '';
              visibility: hidden;
              height: 0;
            }
            box-sizing: border-box;
            width: 100%;
            padding: 0 53px;
            margin-bottom: 5px;
          }
          .login-form-box-header {
            padding: 18px 30px 10px;
            height: 40px;
            text-align: left;
            position: relative;
          }
        }
      }
      position: relative;
      width: 100%;
      margin-top: 29px;
    }
    .login-logo-conatiner {
    }
    width: 685px;
    margin: 0 auto;
    position: relative;
    padding-bottom: 50px;
    top: 10%;
  }
  .login-page-bg {
    position: fixed;
    z-index: -6;
    bottom: 0;
    overflow: auto;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  overflow: auto;
  position: absolute;
  height: 100%;
  width: 100%;
  vertical-align: middle;
  text-align: center;
}
</style>
